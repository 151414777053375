






import { Vue, Component, Prop } from 'vue-property-decorator';
import IndexableInterface from '@/core/interfaces/IndexableInterface';

@Component
export default class Icon extends Vue {
  /**
   * Props
   */
  @Prop() private name!: string;
  @Prop({ default: '' }) private className!: string;
  @Prop({ default: () => {/**/} }) private classNames!: object;
  @Prop({ default: false, type: Boolean }) private left!: boolean;
  @Prop({ default: false, type: Boolean }) private right!: boolean;
  @Prop({ default: false, type: Boolean }) private xSmall!: boolean;
  @Prop({ default: false, type: Boolean }) private small!: boolean;
  @Prop({ default: false, type: Boolean }) private large!: boolean;
  @Prop() private color?: string;

  /**
   * Data
   */
  private get elementClassNames(): object {
    const classes: IndexableInterface = {
      'custom-icon': true,
      'material-icons-outlined': true,
      [this.className]: true,
      'right': this.right,
      'left': this.left,
      'x-small': this.xSmall,
      'small': this.small,
      'large': this.large,
      ...this.classNames,
    };

    if (this.color) {
      classes[this.color + '--text'] = true;
    }

    return classes;
  }
}
