






















































































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';

import ORMCollection from '@/core/bridge/orm/ORMCollection';
import Pagination from '@/core/support/pagination/Pagination';
import ApiORMQueryBuilderExtended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderExtended';
import Search from '@/core/support/search/Search';

import DataTableLimitSelect from './table/tools/DataTableLimitSelect.vue';
import DataTableSearchField from './table/tools/DataTableSearchField.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import Header from '@/shared/resources/components/Header.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import ORMModel from '@/core/bridge/orm/ORMModel';
import ItemCollection from '@/core/support/collection/ItemCollection';
import Table from '@/shared/resources/components/tables/data-table/table/Table.vue';
import TableToolsTop from '@/shared/resources/components/tables/data-table/table/TableToolsTop.vue';
import TableToolsBottom from '@/shared/resources/components/tables/data-table/table/TableToolsBottom.vue';
import TableTemplateInterface from '@/shared/lib/interfaces/TableTemplateInterface';
import { DataTableHeader } from 'vuetify';
import { indexByAttr } from '@/core/helpers/utils/ArrayUtils';
import TableSettingsInterface from '@/shared/lib/interfaces/TableSettingsInterface';
import SearchObjectInterface from '@/shared/lib/interfaces/SearchObjectInterface';
import { scrollToElement } from '@/shared/lib/helpers/scrollTo';
import { PaginationObjectInterface, PaginationSortInterface } from '@/core/support/pagination/PaginationInterface';

const defaultObjectProp = () => {
  //
};

@Component({
  components: {
    TableToolsBottom,
    TableToolsTop,
    Table,
    GridCol,
    GridRow,
    Header,
    Card,
    DataTableSearchField,
    DataTableLimitSelect,
  },
})
export default class DataTable extends Vue {
  /**
   * Refs
   */
  @Ref() private dataTableRef!: HTMLElement;

  /**
   * Props
   */
  @Prop() private query?: ApiORMQueryBuilderExtended;
  @Prop() private items?: ORMModel[];
  @Prop() private headers!: DataTableHeader[];
  @Prop() private searchOptions?: any[]; // TODO make some contract
  @Prop() private name?: string;
  @Prop() private title?: string;
  @Prop({ default: defaultObjectProp }) private template?: TableTemplateInterface;
  @Prop({ default: 'id' }) private primaryKey!: string;
  @Prop({ default: false, type: Boolean }) private noPagination!: boolean;
  @Prop({ default: false, type: Boolean }) private noSort!: boolean;
  @Prop({ default: false, type: Boolean }) private rowsClickable!: boolean;
  @Prop({ default: false, type: Boolean }) private indexColumn!: boolean;
  @Prop({ default: false, type: Boolean }) private noShadow!: boolean;
  @Prop({ default: 15 }) private limit!: number;
  @Prop({ default: () => ({ field: 'id', desc: 'desc' }) }) private sort!: PaginationSortInterface;
  @Prop({ default: 'Brak wyników' }) private emptyInfo!: string;
  @Prop() private itemClass?: any;

  /**
   * Data
   */
  private collection: ItemCollection | ORMCollection = new ItemCollection();

  private queryInitiated: boolean = false;
  private loading: boolean = false;

  private defaultTemplate: TableTemplateInterface = {
    search: true,
    limitSelect: true,

    topPagination: true,
    topLimit: false,

    bottomPagination: true,
    bottomLimit: true,
  };

  /**
   * Getters
   */
  private get templateSettings(): TableTemplateInterface {
    return {
      ...this.defaultTemplate,
      ...this.template,
    };
  }

  private get pagination(): Pagination {
    this.syncCachedPagination();
    return this.collection.pagination;
  }

  private get search(): Search {
    this.syncCachedSearch();
    return this.collection.search;
  }

  private get hasQueryBasedData(): boolean {
    return !!this.query;
  }

  private get itemsToDisplay(): any[] {
    if (this.hasQueryBasedData) {
      return this.collection.items;
    } else {
      return this.noPagination
          ? this.collection.data
          : this.collection.paginatedItems;
    }
  }

  private get tableHeaders(): DataTableHeader[] {
    let tableHeaders = this.headers;

    if (this.indexColumn) {
      tableHeaders.unshift({
        value: 'index',
        text: 'Lp.',
        class: 'table-col-index',
        width: 50,
        // sortable: false,
      });
    }

    if (this.noSort) {
      tableHeaders = tableHeaders.map((tableHeader: DataTableHeader) => {
        return {
          ...tableHeader,
          sortable: false,
        };
      });
    }

    return tableHeaders;
  }

  private get cacheEnabled(): boolean {
    return !!this.name;
  }

  /**
   * Display getters
   */
  private get displayTopTools(): boolean {
    return !!this.template?.search ||
        !!this.template?.topLimit ||
        !!this.template?.topPagination ||
        !!this.title;
  }

  private get displayBottomTools(): boolean {
    return !this.noPagination;
  }

  /**
   * Class names
   */
  private get tableClassNames(): any {
    return {
      'custom-table': true,
      'data-table--rows-clickable': this.rowsClickable,
    };
  }

  /**
   * Lifecycle hooks
   */
  private async created() {
    if (this.hasQueryBasedData) {
      await this.fetchData();
      this.queryInitiated = true;
    }

    if (this.items) {
      this.collection.addMany(this.items);
      this.pagination.setLimit(this.noPagination ? -1 : this.limit);
      this.pagination.setPage(1);
    }
  }

  /**
   * Methods
   */
  private async fetchData() {
    if (!this.query || this.loading) {
      return;
    }

    this.loading = true;

    this.query.refreshAxiosInstance();

    try {
      this.collection = await this.query
          .where(this.search.getRequestData())
          .page(this.pagination.getPage(1))
          .limit(this.pagination.getLimit(this.limit))
          .sort(this.pagination.getSort(this.sort))
          .fetch();

      this.$emit('fetch', this.collection);
    } catch (e) {
      this.$emit('error', e);
    }

    this.loading = false;
  }

  private calculateRowIndex(item: any) {
    if (this.collection instanceof ORMCollection) {
      const index = this.collection.ids.indexOf(item.id);
      return (this.pagination ? this.pagination.offset : 0) + index + 1;
    } else if (this.items) {
      const index = indexByAttr(this.items, this.primaryKey, item[this.primaryKey]);
      return index + 1;
    }

    return null;
  }

  private cacheTableSettings() {
    if (!this.cacheEnabled) {
      return;
    }

    // UISettings.cacheTableSettings(this.name as string, {
    //   pagination: this.pagination.toObject(),
    //   search: this.search.toObject(),
    // });
  }

  /**
   * Cache handling
   */
  private syncCachedPagination() {
    const pagination: PaginationObjectInterface | undefined = this.getCachedSettings()?.pagination;

    if (pagination) {
      this.collection.pagination.sync(
          pagination.page,
          pagination.limit,
          pagination.sort,
      );
    }
  }

  private syncCachedSearch() {
    const search: SearchObjectInterface | undefined = this.getCachedSettings()?.search;

    if (search) {
      this.collection.search.sync(
          search.query,
          search.field,
      );
    }
  }

  private getCachedSettings(): TableSettingsInterface | null {
    // TODO
    // if (!this.cacheEnabled) {
    return null;
    // }
    //
    // const cachedSettings: TableSettingsInterface = UISettings.getCacheTableSettings(this.name as string);
    //
    // if (!cachedSettings) {
    //   return null;
    // }
    //
    // return cachedSettings;
  }

  /**
   * Handlers
   */
  private async onUpdatePage(page: number) {
    this.pagination.setPage(page);
    await this.fetchData();

    scrollToElement(this.dataTableRef);
  }

  private onUpdateSort(sort: PaginationSortInterface[]) {
    this.pagination.setSortArray(sort);
    this.fetchData();
  }

  private onUpdateLimit(limit: number) {
    this.pagination.setLimit(limit);
    this.pagination.setPage(1);
    this.fetchData();
  }

  private onUpdateSearch({ searchString, searchField }: { searchString: string, searchField: string }) {
    this.search.queryString = searchString;
    this.search.queryField = searchField;

    this.pagination.setPage(1);

    this.fetchData();
    this.cacheTableSettings();
  }

  private onRowClick(rowData: any) { // TODO interface
    if (!this.rowsClickable) {
      return;
    }

    this.$emit('row:click', rowData);
  }

  /**
   * Watchers
   */
  @Watch('query')
  private watchQueryChange() {
    if (this.queryInitiated) {
      this.fetchData();
    }
  }

  @Watch('items', { deep: true })
  private watchItemsChange(items: ORMModel[]) {
    this.collection.data = items;
  }

  @Watch('pagination', { deep: true })
  private watchPaginationChange() {
    this.cacheTableSettings();
  }
}
