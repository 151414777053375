
























































import { Vue, Component, Prop } from 'vue-property-decorator';

import Header from '@/shared/resources/components/Header.vue';
import IndexableInterface from '@/core/interfaces/IndexableInterface';
import Loader from '@/shared/resources/components/Loader.vue';
import Icon from '@/shared/resources/components/Icon.vue';

@Component({
  components: {
    Icon,
    Loader,
    Header,
  },
})
export default class Card extends Vue {
  /**
   * Props
   */
  @Prop() private title?: string;
  @Prop() private titleIcon?: string;
  @Prop() private subtitle?: string;
  @Prop({ default: 'default' }) private type?: string;
  @Prop({ default: '' }) private className?: string;
  @Prop({ default: false, type: Boolean }) private loading?: boolean;
  @Prop({ default: false, type: Boolean }) private hasError?: boolean;
  @Prop({ default: false, type: Boolean }) private transparent?: boolean;
  @Prop({ default: false, type: Boolean }) private noPadding?: boolean;
  @Prop({ default: false, type: Boolean }) private noShadow!: boolean;
  @Prop({ default: false, type: Boolean }) private loadingCenter!: boolean;

  /**
   * Class names
   */
  private get classNames(): IndexableInterface {
    const classNames: IndexableInterface = {
      'custom-card--no-padding': this.noPadding || this.transparent,
      'custom-card--no-shadow': this.noShadow || this.transparent,
      'custom-card--no-bg': this.transparent,
      'custom-card--loading-center': this.loadingCenter,
    };

    if (this.className) {
      classNames[this.className] = true;
    }

    if (this.hasError) {
      classNames['custom-card--has-error'] = true;
      classNames['has-error'] = true;
    }

    return classNames;
  }
}
